.footer{background: #f8f6f2;padding: 60px 0;}
.menuGrid{display: grid;grid-template-columns: repeat(4,1fr);}
.griditem .itemHeading{margin-bottom: 10px;}
.socialIcons{display: flex;align-items: center;gap: 10px;margin: 30px 0;}
.socialIcons li{filter: grayscale(100%);}
.socialIcons li:hover{filter: unset;}
.socialIcons li img{width: 30px;height: 30px;}

.socialInputsFields .Inputs{margin-bottom: 15px;}
.socialInputsFields button{border-radius: 8px;}
.socialInputsFields .Inputs input{padding: 10px;border: 1px solid #ced4da;border-radius: 4px;width: 100%;}

.footerlinktitle{display: flex;align-items: center;justify-content: space-between;}
.footerlinktitle .arw{display: none;}

@media screen and (max-width: 680px) {
    .footer{padding: 30px 0;}
    .menuGrid{grid-template-columns: repeat(1,1fr);gap: 10px;}
    .footerlinktitle .arw{display: unset;transform: rotate(-43deg);height: 0px;padding: 4px;}
    .footerlinktitle:not(:last-child){border-bottom: 1px solid #d8d7d5;}
    .griditem .itemHeading{color: #000;font-size: 16px;}
    .griditem .sublowercateogry{opacity: 0;height: 0;visibility: hidden;}
    .griditem.active .sublowercateogry{opacity: 1;height: 100%;transition: 0.3s;visibility: unset;}

}