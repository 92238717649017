.header{border-top: 12px solid #466759;border-bottom: 1px solid #cccccc;background: #fff;padding: 20px 0;position: sticky;text-align: center;top: 0;z-index: 99;}

.loginBox{position: relative;}
.loginBox .loginIcon {position: absolute;top: -40px;cursor: pointer; right: 70px; width: 30px;height: 30px;border: 1px solid #959595;border-radius: 50%;}

.webNavs{margin-top: 20px;}
.navItems{display: flex;gap: 80px;align-items: center;justify-content: center;}
.navItems li{position: relative;display: flex;justify-content: space-between;align-items: flex-start;gap: 10px;}
.navItems li .arw{padding: 4px;margin-top: 4px;}
.navItems .subCategoryMenu{border-top: 4px solid #466759;     background-color: #fff;position: absolute;top: -8px;transform: translateY(50px);transition: 0.4s; opacity: 0; visibility: hidden; min-width: 250px;z-index: 10;box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;}
.navItems .subCategoryMenu li{    padding: 10px 25px;text-transform: uppercase; border-bottom: solid #ccc 1px;}
.navItems  li a:hover{color: #466759;font-weight: 500;}
.navItems li:hover .subCategoryMenu{opacity: 1;visibility: unset;}


.overlay{background: rgba(120, 118, 116,0.6);position: fixed;top: 0;left: 0;z-index: 1050;display: none; justify-content: center;align-items: center; width: 100%;height: 100%;overflow: hidden;outline: 0;}
.loginBox.active .overlay{display: flex;}
.left{justify-content: flex-end}
.Boxmodal{background: #fff;border-radius: 8px;padding: 15px ;min-width: 500px;text-align: left;}
.loginHead{display: flex;justify-content: space-between;padding: 20px 15px;padding-top: 0; border-bottom: 1px solid #dee2e6;}
.fields{padding: 20px 15px;}

.mobileHamburger{display: none;}
.otpBox{display: flex;flex-direction: column;gap: 10px;padding: 20px 50px;align-items: center;}
.otpBox .otpInputs{display: flex;gap: 15px;}


/* bottomHeaderFixed */
.bottomHeaderFixed{position: -webkit-fixed;height: 65px;width: 100%; position: fixed;display: flex;align-items: center;justify-content: center;bottom: 0;z-index: 100;box-shadow: 0 0 10px 0 rgba(0,0,0,.1);background: #fff;}
/* bottomHeaderFixed */

@media screen and (max-width: 600px) {
    .header{padding: 10px 0;}
    .loginBox .loginIcon{right: 25px;}

    .Boxmodal{min-width: unset;width: 320px;}


    .webNavs{display: none;}
    .mobiletabs .overlay{display: none;}
    .mobiletabs.active .overlay{display: flex;}
    .mobileHamburger{position: absolute;top: 15px;right: 15px;  display: block;  }
    .mobileHamburgerclose{width: 20px;height: 20px;margin-right: 10px;margin-left: auto;}
    .mobileHamburgerclose svg{height: 20px;}
    .mobileHamburgerclose svg path{fill: #fff;}
    .btnmenu{background-color: transparent;border: none;box-shadow: none;height: 16px;margin-top: 0;position: relative;width: 25px;}
    .btnmenu span{background-color: rgba(0,0,0,.55);border-radius: 2px;display: block;height: 2px;position: absolute;right: 0;transition: 0.4s;width: 100%;}
    .btnmenu span:first-child{top: 0;width: 100%;}
    .btnmenu span:nth-child(2){top: 50%;transform: translateY(-50%);width: 100%;}
    .btnmenu span:nth-child(3) {bottom: 0;width: 100%;}

    .mobilenavitems{background-color: #1c1b1b; height: 100%;padding: 0 10px;padding-top: 50px;position: relative;overflow-y: auto;transition: 0.4s;transition-duration: 0.4s;/* transform: translateX(100%); */width: 320px;z-index: 9;}
    
    .mobilenavitems ul>li> .listHead{border-bottom: solid #5c5c5c 1px;margin-bottom: 1px; position: relative;z-index: 1; padding: 20px 15px; display: flex;align-items: center;justify-content: space-between;}
    .listHead .arw{ border: solid white;border-width: 0 1px 1px 0;height: 9px;transform: rotate(-46deg);}
    .subnavitems{height: 0;opacity: 0;visibility: hidden;}
    .mobilenavitems ul li.active .subnavitems{height: 100%;opacity: 1;transition: 0.3s;visibility: unset;}
    .mobilenavitems ul li.active .listHead .arw{transform: rotate(43deg);}
    .subnavitems li{padding: 15px;text-align: left;}




}

