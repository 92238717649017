*,::before,::after{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;outline: none;}
body{overflow:hidden;overflow-y:auto;font-family: 'Poppins';}
html{scroll-behavior: smooth}
body,ul,ol,li,h1,h2,h3,h4,h5,h6,figure,p,strong{padding:0;margin:0;list-style:none;}
body,p{font-size:12px;color:#666;line-height:normal;}
h1,h2,h3,h4,h5,h6{font-weight:600}
a,.btn,button,input,select{text-decoration:none;outline:none;}
select{cursor:pointer;background: transparent;}
.container{padding:0 15px;margin:0 auto;width: 100%; max-width:1270px;}
.container-fluid{padding:0 15px;margin:0 auto;width:100%}
img:not([src]){opacity:0}
.scrollBar::-webkit-scrollbar{width:8px;height:8px;background-color:rgba(0,0,0,0.1);border-radius:20px;position:absolute;z-index:10000}
.scrollBar::-webkit-scrollbar-thumb{background-color:#bfbfbf;border-radius:20px}
.scrollBar::-moz-scrollbar{width:8px;height:8px;background-color:rgba(255,255,255,0.1);position:absolute;z-index:10000;right:0}
.scrollBar::-moz-scrollbar-thumb{background-color:#bfbfbf;border-radius:20px}
/* Chrome, Safari, Edge, Opera */

.imgResponsive{max-width:100%;height:auto;max-height:100%}

/* fonts size */
.font55 {font-size: 55px;}
.font45 {font-size: 45px;}
.font40 {font-size: 40px;}
.font35 {font-size: 35px;}
.font30{font-size: 30px;}
.font25{font-size: 25px;}
.font22{font-size: 22px}
.font20{font-size: 20px;}
.font18{font-size: 18px}
.font17{font-size: 17px}
.font16{font-size: 16px;}
.font15{font-size: 15px;}
.font14{font-size: 14px;}
.font13{font-size: 13px;}
.font12{font-size: 12px;}
.font10{font-size: 10px; }

/* fonts size */

.text46675{color: #466759;}
.text506E61{color: #506E61;}
.text000{color: #000;}
.textfff{color: #fff;}
.text333{color: #333;}
.text666{color: #666;}
.textff0000{color: #ff0000;}
.text505257{color: #505257;}
.text552814{color: #552814;}
.text562811{color: #562811;}
.text416941{color: #416941;}
.text913C15{color: #913C15;}
.text285064{color: #285064;}
.text145A3C{color: #145A3C;}
.text5F4670{color: #5F4670;}
.text2e282a{color: #2e282a;}
.text665D59{color: #665D59;}
.textb5b5b5{color: #b5b5b5;}


.mb50{margin-bottom: 50px;}
.mb40{margin-bottom: 40px;}
.mb30{margin-bottom: 30px;}
.mb25{margin-bottom: 25px;}  
.mb22{margin-bottom: 22px;}  
.mb20{margin-bottom: 20px;}   
.mb15{margin-bottom: 15px;}
.mb12{margin-bottom: 12px;}
.mb10{margin-bottom: 10px;}
.mb5{margin-bottom:  5px}

.mt20{margin-top: 20px;}
.mt30{margin-top: 30px;}
.mt60{margin-top: 60px;}
.mt50{margin-top: 50px;}

.pb30{padding-bottom: 30px;}
.pb40{padding-bottom: 40px;}
.pb50{padding-bottom: 50px;}
.hidden {display: none;}
.mp-0{ margin: 0 !important;padding: 0 !important;}
.pt30{padding-top: 30px;}
.pt50{padding-top: 50px;}
.pt20{padding-top: 20px;}
.pt40{padding-top: 40px;}
.mr20{margin-right: 20px;}
.dblock{display: block;}
.dnone{display: none;}
.nowrap{white-space: nowrap;}
.backgroundf5f5f5 {background: #F5F5F5;}


.textUpper{text-transform: uppercase;}
.objContain{object-fit: contain;}
.textcenter{text-align: center;}
.textcap{text-transform: capitalize;}
.textshadowblack{text-shadow: -1px 1px 0 #000,1px 1px 0 #000,1px -1px 0 #000,-1px -1px 0 #000;}


.mr5px{margin-right: 5px;}

.fontBold{font-weight: bold;}
.fontMedium{font-weight: 400;}
.fontsemiBold{font-weight: 500;}
.fontThin{font-weight: 300;}
.font600{font-weight: 600;}
.font200{font-weight: 200;}
.font100{font-weight: 100;}

.crossicon{width: 15px;height: 15px;fill: #333;cursor: pointer;}

.inputGrp{display: flex;flex-direction: column;gap: 10px;}
.inputGrp input{width: 100%;padding: 15px ; border: 0px solid transparent;border-radius: 0;border-bottom: 1px solid #aaa;outline: none;margin-bottom: 20px;transition: all .5s ease;}
.inputGrp input:focus {border-bottom-color: #008080;box-shadow: 0 0 5px rgba(0,80,80,.4);border-radius: 4px;}
.otpInputs input{width: 40px;height: 40px;border-radius: 8px;padding: 10px;text-align: center;font-size: 14px;border: solid 1px #ced4da;}
.otpInputs input:focus{border: 2px solid #466759;transition: 0.1s;}

.lineHeight50{line-height: 50px;}
.lineHeight35{line-height: 35px;}
.lineHeight30{line-height: 30px;}
.lineHeight32{line-height: 32px;}
.lineHeight28{line-height: 28px;}
.lineHeight27{line-height: 27px;}
.lineHeight25{line-height: 25px;}
.lineHeight24{line-height: 24px;}
.lineHeight23{line-height: 23px;}
.lineHeight22{line-height: 22px;}
.lineHeight20{line-height: 20px;}
.lineHeight18{line-height: 18px;}

.hideScroller::-webkit-scrollbar{display: none;}
.posR{position: relative;}
.otpApiError{color: #eb1c1c;display: block;text-align: center;font-size: 15px;padding-bottom: 5px;}
.errorText{bottom: -14px;left: 0;font-size: 10px;color: red;font-style: italic;line-height: initial;}
.cursorP{cursor: pointer;}

.btn{padding:13px 20px;text-align:center;cursor:pointer;border:none;outline: none; line-height:20px;border-radius:50px;transition:.5s}
.btn-primary{background-color: #506E61;color: #fff;min-width: 211px; border:solid #506E61 1px;}
.btn-primary:hover{background-color: #fff;color: #506E61; border-color: #506E61; font-weight: 600;  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);}
.btn-secondry{background: #466759;color: #fff;border: solid 2px #fff ;}
.sectionHeading{text-align: center; font-size: 35px;font-weight: 500; text-transform: capitalize;position: relative;padding-bottom: 10px; margin-bottom: 30px;}
.sectionHeading::before{content: ''; position: absolute;width: 120px;height: 1px;background: #ddd;bottom: 1px;left: calc(50% - 60px);}
.sectionHeading::after{content: "";position: absolute;width: 40px;height: 3px;background: #466759;bottom: 0;left: calc(50% - 20px);}
.sectionHeading.text542916::after{background: #542916;}
h2.sectionHeading.leftHeading{text-align: left;}
h2.sectionHeading.leftHeading::before{left: calc(10% - 60px);}
h2.sectionHeading.leftHeading::after{left: calc(10% - 20px);}
.corowselarw{position: absolute;z-index: 11; top: 40%; width: 50px;height: 50px;background: #d9d9d9;display: flex;align-items: center;justify-content: center;border-radius: 50px;scroll-behavior: smooth;}
.corowselleft{left: -25px;}
.corowselright{right: -25px;}
.arw{border: solid black;border-width: 0 1px 1px 0;display: inline-block;padding: 5px;}
.arwright{transform: rotate(-45deg);-webkit-transform: rotate(-45deg);  }
.arwleft{transform: rotate(180deg);-webkit-transform: rotate(180deg);}  
.arwdown{transform: rotate(42deg);-webkit-transform: rotate(42deg);}  
.displayNone{display: none !important;}
.form-control{background:#FFFFFF;border:1px solid #E1DDDD;border-radius:10px;}
.corouselNavs{display: flex;align-items: center;justify-content: center;gap: 10px;margin-top: 10px;}
.corouselNavs .dot{width: 8px;height: 8px;border-radius: 50px;background: #c7c8c7;}
.corouselNavs .dot.active{background: #466759;transition: 0.5s;}

.lineclamp7{display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 7;overflow: hidden;}
.lineclamp6{display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 6;overflow: hidden;}
.lineclamp5{display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 5;overflow: hidden;}
.lineclamp4{display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 4;overflow: hidden;}
.lineclamp3{display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;}
.lineclamp2{display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;}
.lineclamp1{display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 1;overflow: hidden;}

.healthy{ background: #50b179 !important;}
.mild{background: #39a164 !important;}
.moderate{background: #dfb03c !important;}
.severe{background: #d38a34 !important;}
.other{background: #c25150 !important;}

@media screen and (max-width: 680px) {
    .fontsm30{font-size: 30px;}
    .fontsm28{font-size: 28px;}
    .fontsm25{font-size: 25px;}
    .fontsm21{font-size: 21px;}
    .fontsm20{font-size: 20px;}
    .fontsm18{font-size: 18px;}
    .fontsm16{font-size: 16px;}
    .fontsm14{font-size: 14px;}
    .fontsm13{font-size: 13px;}
    .fontsm12{font-size: 12px;}
    .fontsm10{font-size: 10px;}

    .normallineheight{line-height: normal;}
    .resplineheight18{line-height: 18px;}
    .resplineheight29{line-height: 29px;}
    .resplineheight20{line-height: 20px;}

    .sectionHeading{font-size: 20px;}
    .sectionHeading::after{bottom: 10px;}
    .sectionHeading::before{bottom: 11px;}
    .sectionHeading{padding-bottom: 20px; margin-bottom: 20px;}
    .corowselright{right: 0;}
    .corowselleft{left: 0;}
    .textCentersm{text-align: center;}
    .text-sd-center{
        text-align: center !important;
    }
    h2.sectionHeading.leftHeading{text-align: center;}
    h2.sectionHeading.leftHeading::before,h2.sectionHeading.leftHeading::after {
        left: 50%;
        transform: translateX(-50%);
    }

}