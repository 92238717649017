.Header_header__MNlwg{border-top: 12px solid #466759;border-bottom: 1px solid #cccccc;background: #fff;padding: 20px 0;position: -webkit-sticky;position: sticky;text-align: center;top: 0;z-index: 99;}

.Header_loginBox__WYF1C{position: relative;}
.Header_loginBox__WYF1C .Header_loginIcon__3ndFE {position: absolute;top: -40px;cursor: pointer; right: 70px; width: 30px;height: 30px;border: 1px solid #959595;border-radius: 50%;}

.Header_webNavs__nUk6K{margin-top: 20px;}
.Header_navItems__7q_VB{display: flex;gap: 80px;align-items: center;justify-content: center;}
.Header_navItems__7q_VB li{position: relative;display: flex;justify-content: space-between;align-items: flex-start;gap: 10px;}
.Header_navItems__7q_VB li .Header_arw__Gfhvz{padding: 4px;margin-top: 4px;}
.Header_navItems__7q_VB .Header_subCategoryMenu__QPJNV{border-top: 4px solid #466759;     background-color: #fff;position: absolute;top: -8px;transform: translateY(50px);transition: 0.4s; opacity: 0; visibility: hidden; min-width: 250px;z-index: 10;box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;}
.Header_navItems__7q_VB .Header_subCategoryMenu__QPJNV li{    padding: 10px 25px;text-transform: uppercase; border-bottom: solid #ccc 1px;}
.Header_navItems__7q_VB  li a:hover{color: #466759;font-weight: 500;}
.Header_navItems__7q_VB li:hover .Header_subCategoryMenu__QPJNV{opacity: 1;visibility: unset;}


.Header_overlay__xHSSg{background: rgba(120, 118, 116,0.6);position: fixed;top: 0;left: 0;z-index: 1050;display: none; justify-content: center;align-items: center; width: 100%;height: 100%;overflow: hidden;outline: 0;}
.Header_loginBox__WYF1C.Header_active___z8uU .Header_overlay__xHSSg{display: flex;}
.Header_left__IqqOr{justify-content: flex-end}
.Header_Boxmodal__4JwsC{background: #fff;border-radius: 8px;padding: 15px ;min-width: 500px;text-align: left;}
.Header_loginHead__3YL6_{display: flex;justify-content: space-between;padding: 20px 15px;padding-top: 0; border-bottom: 1px solid #dee2e6;}
.Header_fields__vPbhH{padding: 20px 15px;}

.Header_mobileHamburger__AAUHc{display: none;}
.Header_otpBox__Ti_cn{display: flex;flex-direction: column;gap: 10px;padding: 20px 50px;align-items: center;}
.Header_otpBox__Ti_cn .Header_otpInputs__af8rV{display: flex;gap: 15px;}


/* bottomHeaderFixed */
.Header_bottomHeaderFixed__sHqIN{position: -webkit-fixed;height: 65px;width: 100%; position: fixed;display: flex;align-items: center;justify-content: center;bottom: 0;z-index: 100;box-shadow: 0 0 10px 0 rgba(0,0,0,.1);background: #fff;}
/* bottomHeaderFixed */

@media screen and (max-width: 600px) {
    .Header_header__MNlwg{padding: 10px 0;}
    .Header_loginBox__WYF1C .Header_loginIcon__3ndFE{right: 25px;}

    .Header_Boxmodal__4JwsC{min-width: unset;width: 320px;}


    .Header_webNavs__nUk6K{display: none;}
    .Header_mobiletabs__ZQ5I8 .Header_overlay__xHSSg{display: none;}
    .Header_mobiletabs__ZQ5I8.Header_active___z8uU .Header_overlay__xHSSg{display: flex;}
    .Header_mobileHamburger__AAUHc{position: absolute;top: 15px;right: 15px;  display: block;  }
    .Header_mobileHamburgerclose__v7n9_{width: 20px;height: 20px;margin-right: 10px;margin-left: auto;}
    .Header_mobileHamburgerclose__v7n9_ svg{height: 20px;}
    .Header_mobileHamburgerclose__v7n9_ svg path{fill: #fff;}
    .Header_btnmenu__cKdNN{background-color: transparent;border: none;box-shadow: none;height: 16px;margin-top: 0;position: relative;width: 25px;}
    .Header_btnmenu__cKdNN span{background-color: rgba(0,0,0,.55);border-radius: 2px;display: block;height: 2px;position: absolute;right: 0;transition: 0.4s;width: 100%;}
    .Header_btnmenu__cKdNN span:first-child{top: 0;width: 100%;}
    .Header_btnmenu__cKdNN span:nth-child(2){top: 50%;transform: translateY(-50%);width: 100%;}
    .Header_btnmenu__cKdNN span:nth-child(3) {bottom: 0;width: 100%;}

    .Header_mobilenavitems__026pJ{background-color: #1c1b1b; height: 100%;padding: 0 10px;padding-top: 50px;position: relative;overflow-y: auto;transition: 0.4s;transition-duration: 0.4s;/* transform: translateX(100%); */width: 320px;z-index: 9;}
    
    .Header_mobilenavitems__026pJ ul>li> .Header_listHead__AM6IQ{border-bottom: solid #5c5c5c 1px;margin-bottom: 1px; position: relative;z-index: 1; padding: 20px 15px; display: flex;align-items: center;justify-content: space-between;}
    .Header_listHead__AM6IQ .Header_arw__Gfhvz{ border: solid white;border-width: 0 1px 1px 0;height: 9px;transform: rotate(-46deg);}
    .Header_subnavitems__b0Uxt{height: 0;opacity: 0;visibility: hidden;}
    .Header_mobilenavitems__026pJ ul li.Header_active___z8uU .Header_subnavitems__b0Uxt{height: 100%;opacity: 1;transition: 0.3s;visibility: unset;}
    .Header_mobilenavitems__026pJ ul li.Header_active___z8uU .Header_listHead__AM6IQ .Header_arw__Gfhvz{transform: rotate(43deg);}
    .Header_subnavitems__b0Uxt li{padding: 15px;text-align: left;}




}


.TermAndCopyright_copyrights__ugdEs ul{display: flex;justify-content: center;align-items: center;gap: 30px;padding: 30px 0;}                                                                                                                                                                                                                                                                                                                                        
.TermAndCopyright_copyrights__ugdEs ul li:not(:last-child){border-right:  solid #ccc 1px;padding-right: 20px;}

@media screen and (max-width: 680px) {
.TermAndCopyright_copyrights__ugdEs{padding: 0;}
.TermAndCopyright_copyrights__ugdEs ul{overflow: auto;gap: 0;padding: 10px 0;justify-content: flex-start ;}
.TermAndCopyright_copyrights__ugdEs ul::-webkit-scrollbar{display: none;}
.TermAndCopyright_copyrights__ugdEs ul li{white-space: nowrap;padding: 0 15px;}
}
.Footer_footer__WM0Jy{background: #f8f6f2;padding: 60px 0;}
.Footer_menuGrid__vAzC_{display: grid;grid-template-columns: repeat(4,1fr);}
.Footer_griditem__df9vk .Footer_itemHeading__WXaUY{margin-bottom: 10px;}
.Footer_socialIcons__lUD_B{display: flex;align-items: center;gap: 10px;margin: 30px 0;}
.Footer_socialIcons__lUD_B li{filter: grayscale(100%);}
.Footer_socialIcons__lUD_B li:hover{filter: unset;}
.Footer_socialIcons__lUD_B li img{width: 30px;height: 30px;}

.Footer_socialInputsFields__whGBK .Footer_Inputs__bR_Oh{margin-bottom: 15px;}
.Footer_socialInputsFields__whGBK button{border-radius: 8px;}
.Footer_socialInputsFields__whGBK .Footer_Inputs__bR_Oh input{padding: 10px;border: 1px solid #ced4da;border-radius: 4px;width: 100%;}

.Footer_footerlinktitle__xthbS{display: flex;align-items: center;justify-content: space-between;}
.Footer_footerlinktitle__xthbS .Footer_arw__57GyQ{display: none;}

@media screen and (max-width: 680px) {
    .Footer_footer__WM0Jy{padding: 30px 0;}
    .Footer_menuGrid__vAzC_{grid-template-columns: repeat(1,1fr);gap: 10px;}
    .Footer_footerlinktitle__xthbS .Footer_arw__57GyQ{display: unset;transform: rotate(-43deg);height: 0px;padding: 4px;}
    .Footer_footerlinktitle__xthbS:not(:last-child){border-bottom: 1px solid #d8d7d5;}
    .Footer_griditem__df9vk .Footer_itemHeading__WXaUY{color: #000;font-size: 16px;}
    .Footer_griditem__df9vk .Footer_sublowercateogry__2h6F4{opacity: 0;height: 0;visibility: hidden;}
    .Footer_griditem__df9vk.Footer_active__PxCeY .Footer_sublowercateogry__2h6F4{opacity: 1;height: 100%;transition: 0.3s;visibility: unset;}

}
*,::before,::after{box-sizing:border-box;outline: none;}
body{overflow:hidden;overflow-y:auto;font-family: 'Poppins';}
html{scroll-behavior: smooth}
body,ul,ol,li,h1,h2,h3,h4,h5,h6,figure,p,strong{padding:0;margin:0;list-style:none;}
body,p{font-size:12px;color:#666;line-height:normal;}
h1,h2,h3,h4,h5,h6{font-weight:600}
a,.btn,button,input,select{text-decoration:none;outline:none;}
select{cursor:pointer;background: transparent;}
.container{padding:0 15px;margin:0 auto;width: 100%; max-width:1270px;}
.container-fluid{padding:0 15px;margin:0 auto;width:100%}
img:not([src]){opacity:0}
.scrollBar::-webkit-scrollbar{width:8px;height:8px;background-color:rgba(0,0,0,0.1);border-radius:20px;position:absolute;z-index:10000}
.scrollBar::-webkit-scrollbar-thumb{background-color:#bfbfbf;border-radius:20px}
.scrollBar::-moz-scrollbar{width:8px;height:8px;background-color:rgba(255,255,255,0.1);position:absolute;z-index:10000;right:0}
.scrollBar::-moz-scrollbar-thumb{background-color:#bfbfbf;border-radius:20px}
/* Chrome, Safari, Edge, Opera */

.imgResponsive{max-width:100%;height:auto;max-height:100%}

/* fonts size */
.font55 {font-size: 55px;}
.font45 {font-size: 45px;}
.font40 {font-size: 40px;}
.font35 {font-size: 35px;}
.font30{font-size: 30px;}
.font25{font-size: 25px;}
.font22{font-size: 22px}
.font20{font-size: 20px;}
.font18{font-size: 18px}
.font17{font-size: 17px}
.font16{font-size: 16px;}
.font15{font-size: 15px;}
.font14{font-size: 14px;}
.font13{font-size: 13px;}
.font12{font-size: 12px;}
.font10{font-size: 10px; }

/* fonts size */

.text46675{color: #466759;}
.text506E61{color: #506E61;}
.text000{color: #000;}
.textfff{color: #fff;}
.text333{color: #333;}
.text666{color: #666;}
.textff0000{color: #ff0000;}
.text505257{color: #505257;}
.text552814{color: #552814;}
.text562811{color: #562811;}
.text416941{color: #416941;}
.text913C15{color: #913C15;}
.text285064{color: #285064;}
.text145A3C{color: #145A3C;}
.text5F4670{color: #5F4670;}
.text2e282a{color: #2e282a;}
.text665D59{color: #665D59;}
.textb5b5b5{color: #b5b5b5;}


.mb50{margin-bottom: 50px;}
.mb40{margin-bottom: 40px;}
.mb30{margin-bottom: 30px;}
.mb25{margin-bottom: 25px;}  
.mb22{margin-bottom: 22px;}  
.mb20{margin-bottom: 20px;}   
.mb15{margin-bottom: 15px;}
.mb12{margin-bottom: 12px;}
.mb10{margin-bottom: 10px;}
.mb5{margin-bottom:  5px}

.mt20{margin-top: 20px;}
.mt30{margin-top: 30px;}
.mt60{margin-top: 60px;}
.mt50{margin-top: 50px;}

.pb30{padding-bottom: 30px;}
.pb40{padding-bottom: 40px;}
.pb50{padding-bottom: 50px;}
.hidden {display: none;}
.mp-0{ margin: 0 !important;padding: 0 !important;}
.pt30{padding-top: 30px;}
.pt50{padding-top: 50px;}
.pt20{padding-top: 20px;}
.pt40{padding-top: 40px;}
.mr20{margin-right: 20px;}
.dblock{display: block;}
.dnone{display: none;}
.nowrap{white-space: nowrap;}
.backgroundf5f5f5 {background: #F5F5F5;}


.textUpper{text-transform: uppercase;}
.objContain{object-fit: contain;}
.textcenter{text-align: center;}
.textcap{text-transform: capitalize;}
.textshadowblack{text-shadow: -1px 1px 0 #000,1px 1px 0 #000,1px -1px 0 #000,-1px -1px 0 #000;}


.mr5px{margin-right: 5px;}

.fontBold{font-weight: bold;}
.fontMedium{font-weight: 400;}
.fontsemiBold{font-weight: 500;}
.fontThin{font-weight: 300;}
.font600{font-weight: 600;}
.font200{font-weight: 200;}
.font100{font-weight: 100;}

.crossicon{width: 15px;height: 15px;fill: #333;cursor: pointer;}

.inputGrp{display: flex;flex-direction: column;gap: 10px;}
.inputGrp input{width: 100%;padding: 15px ; border: 0px solid transparent;border-radius: 0;border-bottom: 1px solid #aaa;outline: none;margin-bottom: 20px;transition: all .5s ease;}
.inputGrp input:focus {border-bottom-color: #008080;box-shadow: 0 0 5px rgba(0,80,80,.4);border-radius: 4px;}
.otpInputs input{width: 40px;height: 40px;border-radius: 8px;padding: 10px;text-align: center;font-size: 14px;border: solid 1px #ced4da;}
.otpInputs input:focus{border: 2px solid #466759;transition: 0.1s;}

.lineHeight50{line-height: 50px;}
.lineHeight35{line-height: 35px;}
.lineHeight30{line-height: 30px;}
.lineHeight32{line-height: 32px;}
.lineHeight28{line-height: 28px;}
.lineHeight27{line-height: 27px;}
.lineHeight25{line-height: 25px;}
.lineHeight24{line-height: 24px;}
.lineHeight23{line-height: 23px;}
.lineHeight22{line-height: 22px;}
.lineHeight20{line-height: 20px;}
.lineHeight18{line-height: 18px;}

.hideScroller::-webkit-scrollbar{display: none;}
.posR{position: relative;}
.otpApiError{color: #eb1c1c;display: block;text-align: center;font-size: 15px;padding-bottom: 5px;}
.errorText{bottom: -14px;left: 0;font-size: 10px;color: red;font-style: italic;line-height: initial;}
.cursorP{cursor: pointer;}

.btn{padding:13px 20px;text-align:center;cursor:pointer;border:none;outline: none; line-height:20px;border-radius:50px;transition:.5s}
.btn-primary{background-color: #506E61;color: #fff;min-width: 211px; border:solid #506E61 1px;}
.btn-primary:hover{background-color: #fff;color: #506E61; border-color: #506E61; font-weight: 600;  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);}
.btn-secondry{background: #466759;color: #fff;border: solid 2px #fff ;}
.sectionHeading{text-align: center; font-size: 35px;font-weight: 500; text-transform: capitalize;position: relative;padding-bottom: 10px; margin-bottom: 30px;}
.sectionHeading::before{content: ''; position: absolute;width: 120px;height: 1px;background: #ddd;bottom: 1px;left: calc(50% - 60px);}
.sectionHeading::after{content: "";position: absolute;width: 40px;height: 3px;background: #466759;bottom: 0;left: calc(50% - 20px);}
.sectionHeading.text542916::after{background: #542916;}
h2.sectionHeading.leftHeading{text-align: left;}
h2.sectionHeading.leftHeading::before{left: calc(10% - 60px);}
h2.sectionHeading.leftHeading::after{left: calc(10% - 20px);}
.corowselarw{position: absolute;z-index: 11; top: 40%; width: 50px;height: 50px;background: #d9d9d9;display: flex;align-items: center;justify-content: center;border-radius: 50px;scroll-behavior: smooth;}
.corowselleft{left: -25px;}
.corowselright{right: -25px;}
.arw{border: solid black;border-width: 0 1px 1px 0;display: inline-block;padding: 5px;}
.arwright{transform: rotate(-45deg);-webkit-transform: rotate(-45deg);  }
.arwleft{transform: rotate(180deg);-webkit-transform: rotate(180deg);}  
.arwdown{transform: rotate(42deg);-webkit-transform: rotate(42deg);}  
.displayNone{display: none !important;}
.form-control{background:#FFFFFF;border:1px solid #E1DDDD;border-radius:10px;}
.corouselNavs{display: flex;align-items: center;justify-content: center;gap: 10px;margin-top: 10px;}
.corouselNavs .dot{width: 8px;height: 8px;border-radius: 50px;background: #c7c8c7;}
.corouselNavs .dot.active{background: #466759;transition: 0.5s;}

.lineclamp7{display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 7;overflow: hidden;}
.lineclamp6{display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 6;overflow: hidden;}
.lineclamp5{display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 5;overflow: hidden;}
.lineclamp4{display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 4;overflow: hidden;}
.lineclamp3{display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;}
.lineclamp2{display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;}
.lineclamp1{display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 1;overflow: hidden;}

.healthy{ background: #50b179 !important;}
.mild{background: #39a164 !important;}
.moderate{background: #dfb03c !important;}
.severe{background: #d38a34 !important;}
.other{background: #c25150 !important;}

@media screen and (max-width: 680px) {
    .fontsm30{font-size: 30px;}
    .fontsm28{font-size: 28px;}
    .fontsm25{font-size: 25px;}
    .fontsm21{font-size: 21px;}
    .fontsm20{font-size: 20px;}
    .fontsm18{font-size: 18px;}
    .fontsm16{font-size: 16px;}
    .fontsm14{font-size: 14px;}
    .fontsm13{font-size: 13px;}
    .fontsm12{font-size: 12px;}
    .fontsm10{font-size: 10px;}

    .normallineheight{line-height: normal;}
    .resplineheight18{line-height: 18px;}
    .resplineheight29{line-height: 29px;}
    .resplineheight20{line-height: 20px;}

    .sectionHeading{font-size: 20px;}
    .sectionHeading::after{bottom: 10px;}
    .sectionHeading::before{bottom: 11px;}
    .sectionHeading{padding-bottom: 20px; margin-bottom: 20px;}
    .corowselright{right: 0;}
    .corowselleft{left: 0;}
    .textCentersm{text-align: center;}
    .text-sd-center{
        text-align: center !important;
    }
    h2.sectionHeading.leftHeading{text-align: center;}
    h2.sectionHeading.leftHeading::before,h2.sectionHeading.leftHeading::after {
        left: 50%;
        transform: translateX(-50%);
    }

}
@font-face {
font-family: '__poppins_de3e54';
src: url(/_next/static/media/9102fb8415d8f327-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: '__poppins_de3e54';
src: url(/_next/static/media/8b20023e8f811f53-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__poppins_de3e54';
src: url(/_next/static/media/a52d0bf095c248da-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__poppins_de3e54';
src: url(/_next/static/media/98512bf8da1afe43-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__poppins_de3e54';
src: url(/_next/static/media/8001a01b32b71ef5-s.p.ttf) format('truetype');
font-display: swap;
font-weight: bold;
font-style: normal;
}@font-face {font-family: '__poppins_Fallback_de3e54';src: local("Arial");ascent-override: 93.76%;descent-override: 31.25%;line-gap-override: 8.93%;size-adjust: 111.99%
}.__className_de3e54 {font-family: '__poppins_de3e54', '__poppins_Fallback_de3e54'
}

